import React, { useState, useEffect } from 'react';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';

interface Props {
    actionFilters: {
        getFiltriAttiviSearch(id: string, property: string): any;
    };
    property: string;
    overrideProperty: string;
    FiltriAttivi: string | undefined;
}

const FilterMenuSearchText: React.FC<Props> = (props) => {
    const [searchText, setSearchText] = useState<string>('');

    // Sincronizza lo stato locale con `FiltriAttivi`
    useEffect(() => {
        if (!stringIsNullOrEmpty(props.FiltriAttivi)) {
            setSearchText(props.FiltriAttivi || '');
        }
    }, [props.FiltriAttivi]);

    // Esegue il filtro e aggiorna lo stato
    const filter = (text: string) => {
        setSearchText(text);
        props.actionFilters.getFiltriAttiviSearch(text, props.property);
    };

    // Gestisce il submit del form
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        filter(searchText);
    };

    // Resetta il campo di ricerca
    const reset = () => {
        filter('');
    };

    return (
        <div className="relative">
            <div className="filterSearch d-flex flex-row justify-content-between w-100">
                <form className="d-flex flex-row w-100" onSubmit={handleSubmit}>
                    <button type="submit" className="btn btnIcon btnTransaparent p-0">
                        <img
                            className="mr-1"
                            src={require(`../../svg/Search_Gray.png`)}
                            alt="Search Icon"
                        />
                    </button>
                    <input
                        className="filterSearch w-100"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Search"
                        type="text"
                    />
                </form>
                {searchText && (
                    <button
                        type="button"
                        className="btn btnTransaparent p-0"
                        onClick={reset}
                    >
                        <img
                            className="ml-1"
                            height="10"
                            src={require(`../../svg/close.png`)}
                            alt="Reset Icon"
                        />
                    </button>
                )}
            </div>
        </div>
    );
};

export default FilterMenuSearchText;
