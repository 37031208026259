import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FilterValueDto } from '../../Business/Common/CommonBusiness';

interface Props {
    filterData?: FilterValueDto[];
    property: string;
    overrideProperty: string;
    count: string[];
    orderAscending?: boolean;
    propertyInOrder?: string;
    FiltriAttivi: string[]; // Array di valori selezionati
    actionFilters: {
        checkFilter: (value: string) => boolean;
        resetFilter: (value: string) => void;
        updateCount(property: string): string[];
        getFiltriAttivi(id: string, checked: boolean, property: string): void;
        orderBy(property: string, isAscending: boolean): void;
        getFilters(property: string, text: string): void;
    };
}

const FilterMenuCheckbox: React.FC<Props> = (props) => {
    const [filteredData, setFilteredData] = useState<FilterValueDto[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [localSelectedFilters, setLocalSelectedFilters] = useState<string[]>(props.FiltriAttivi);
    // Evita il loop per le funzioni passate nei props
    const getFiltersRef = useRef(props.actionFilters.getFilters);

    // Aggiorna lo stato locale quando `FiltriAttivi` cambia
    useEffect(() => {
        setLocalSelectedFilters(props.FiltriAttivi);
    }, [props.FiltriAttivi]);

    // Filtra localmente i dati
    useEffect(() => {
        if (props.filterData) {
            const localFiltered = props.filterData.filter((item) =>
                item.text?.toLowerCase().includes(searchText.toLowerCase())
            );
            setFilteredData(localFiltered);
        }
    }, [props.filterData, searchText]);

    // Carica i filtri iniziali (una sola volta)
    useEffect(() => {
        getFiltersRef.current(props.property, '');
    }, [props.property]);
    console.log(props.FiltriAttivi)

    const handleSearch = useCallback(
        (text: string) => {
            setSearchText(text);

            if (props.filterData) {
                const localFiltered = props.filterData.filter((item) =>
                    item.text?.toLowerCase().includes(text.toLowerCase())
                );
                setFilteredData(localFiltered);
            }

            // Comunica al server il valore del filtro
            getFiltersRef.current(props.property, text);
        },
        [props.filterData, props.property]
    );

    const handleChange = useCallback(
        (id: string | undefined, event: React.ChangeEvent<HTMLInputElement>) => {
            const checked = event.target.checked;

            if (!id) return;

            // Aggiorna i filtri selezionati localmente
            const newLocalSelectedFilters = checked
                ? [...localSelectedFilters, id]
                : localSelectedFilters.filter((value) => value !== id);

            setLocalSelectedFilters(newLocalSelectedFilters);

            // Aggiorna i filtri attivi nel componente padre
            props.actionFilters.getFiltriAttivi(
                id,
                checked,
                props.overrideProperty === '' ? props.property : props.overrideProperty
            );
        },
        [localSelectedFilters, props.overrideProperty, props.property, props.actionFilters]
    );

    const resetFilters = useCallback(() => {
        setSearchText('');
        setLocalSelectedFilters([]);
        if (props.filterData) {
            setFilteredData(props.filterData);
        }

        // Reset dei filtri attivi nel componente padre
        props.actionFilters.resetFilter(props.property);
    }, [props.filterData, props.property, props.actionFilters]);

    return (
        <div className="relative">
            <div className="filterSearch d-flex flex-row w-100">
                <input
                    className="filterSearch"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search"
                    type="text"
                />
                {localSelectedFilters.length > 0 && (
                    <button
                        type="button"
                        className="btn btnTransaparent px-0"
                        onClick={resetFilters}
                    >
                        <img
                            src={require('../../svg/close.png')}
                            height="13"
                            alt="Reset Filter"
                        />
                    </button>
                )}
            </div>
            <div className="col-12 row mx-0 px-2 mt-2 filterMenu">
                <div className="filterMenuContent w-100">
                    {filteredData.length > 0 ? (
                        filteredData
                            .sort((a, b) =>
                                a.text?.toLowerCase() > b.text?.toLowerCase() ? 1 : -1
                            )
                            .map((item, index) => {
                                const isChecked = localSelectedFilters.includes(item.value!);
                                return (
                                    <div
                                        className="w-100 d-flex flex-row align-items-center mx-0 mt-1"
                                        key={`${item.value}-${index}`}
                                    >
                                        <input
                                            type="checkbox"
                                            className="filterCheckbox mr-2"
                                            checked={isChecked}
                                            onChange={(e) => handleChange(item.value, e)}
                                            id={`${props.property}-${item.value}`}
                                        />
                                        <label
                                            htmlFor={`${props.property}-${item.value}`}
                                            className="voda-regular mb-0"
                                            style={{ display: 'inline' }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.text ?? '',
                                            }}
                                        ></label>
                                    </div>
                                );
                            })
                    ) : (
                        <label>No Values</label>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FilterMenuCheckbox;
