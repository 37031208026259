import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { RootState } from "../Redux/Store/rootStore";
import "../Css/Index.css";
import { NotifyType } from "../Redux/Reducer/NotificationReducer";

interface Props {
    OnModal: boolean;
    ShowFixed?: boolean;
    isOpen: boolean;
    notifyType: NotifyType;
    message: string;
}

const Notification: React.FC<Props> = (props) => {
    const [errorType, setErrorType] = useState("");
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const error = props.message;

    function handleClose() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen && !props.ShowFixed) {
            setTimeout(handleClose, 4000);
        }
    }, [isOpen]);

    useEffect(() => {
        switch (props.notifyType) {
            case 0:
                setErrorType("noNotify");
                break;
            case 1:
                setErrorType("success");
                break;
            case 2:
                setErrorType("error");
                break;
            case 3:
                setErrorType("warning");
                break;
            default:
                break;
        }
    }, [props.notifyType]);

    return isOpen ? (
        <div
            className={`notification w-100 d-flex flex-row align-content-start ${errorType}`}
            style={{
                padding: "10px 15px",
                borderRadius: "5px",
                backgroundColor:
                    errorType === "success" ? "green" :
                    errorType === "error" ? "red" :
                    errorType === "warning" ? "orange" : "gray",
                color: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div className="col d-flex align-items-center">
                <span className="regular">{error}</span>
            </div>
            <div>
                <button
                    className="btn btn-icon trasparent-bg d-flex align-items-center justify-content-center"
                    onClick={handleClose}
                    style={{
                        background: "transparent",
                        border: "none",
                        color: "white",
                        cursor: "pointer",
                        fontSize: "16px",
                    }}
                >
                    ✖
                </button>
            </div>
        </div>
    ) : null;
};


export default Notification;
