import React, { SetStateAction, useContext, useState } from 'react';
import '../../Css/AziendaList.css'
import { ProformaEmailViewModel, ProformaViewModel } from '../../Model/ProformaModels';
import { RenderDetail, DataModalConfirm, QueryObject } from '../../Model/CommonModel';
import { arrayIsNullOrEmpty } from '../../Hook/Common/Utility';
import { RootState } from '../../Redux/Store/rootStore';
import { DarkTheme } from '../../App';
import { SelectGridType } from '../../Hook/Grid/GridRender';
import { SelectFilterTypeGrid } from '../../Hook/Grid/GridRender';
import { useSelector } from 'react-redux';
import { useFilterTableCrud } from '../../Hook/Grid/useFilterTableCrud';
import { GetFilterColumnProformaViewModel, GetProformaViewModel } from '../../Redux/Action/Proforma/ProformaGridAction';
import { DownloadProformaExcell, DownloadProformaFile } from '../../Redux/Action/Proforma/ProformaDownloadAction';
import ProformaComponent from '../Component/Proforma/ProformaComponent';
import { Modal } from 'react-bootstrap';
import ButtonActionIcon from '../../Components/Various/ButtonActionIcon';
import SendEmailForm from '../Component/Proforma/EmailSend';
import ButtonActionIconGoogleIcon from '../../Components/Various/ButtonActionIconGoogleIcon';
import { basePath } from '../../Business/Common/configuration';
import { get } from 'http';
import { ReSendEMail } from '../../Redux/Action/Proforma/ProformaEditAction';
import setLoader from '../../Redux/Action/LoaderAction';
import { formatDateWithTime, formatDateWithTimeformat, formatTime } from '../../Hook/Common/TimeAndDate';

interface Props {
  action: {
    setConfirm(data: DataModalConfirm): any
    setQuery(query: SetStateAction<QueryObject> | undefined): any
    Edit(id: string | undefined): any;
    onDelete(id: string | undefined): any;

  }
  isMobile: boolean;
  renderGrid: RenderDetail[] | undefined;
  data: ProformaViewModel[]
  query: QueryObject
  downloadedList: number[]
}

const ProformaGrid: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  const getFiltersData = (state: RootState) => state.ProformaGridReducer.filter;
  let filterData = useSelector(getFiltersData);

  const {
    filtriAttivi,
    resetFilter,
    closeAll,
    setDateToChildren,
    orderBy,
    resetFilterDate,
    getFilters,
    updateCount,
    getFiltriAttivi,
    count,
    checkFilterinValue,
    checkFilterDateinValue,
    isVisibleFiltriString,
    setIsVisibleFiltriString,
    getFiltriAttiviSearch
  } = useFilterTableCrud<QueryObject>(props.action.setQuery, GetFilterColumnProformaViewModel, props.query);
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [isVisibleModalSendEmail, setIsVisibleModalSendEmail] = useState(false)
  const closeModal = () => {
    setIsVisibleModal(false); setIsVisibleModalSendEmail(false); GetProformaViewModel({}).then(() => {
      setLoader("REMOVE", "GetProformaViewModel")
    });
    setProformaEmailState(undefined);
    setProformaId(undefined);
  };
  const [ProformaId, setProformaId] = useState<string | undefined>(undefined)
  const [ProformaEmail, setProformaEmailState] = useState<ProformaEmailViewModel | undefined>(undefined)
  const thAction = { orderBy };
  const actionFilterCheckbox = { closeAll, updateCount, getFiltriAttivi, orderBy, getFilters, checkFilter: checkFilterinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilter };
  const actionFilterSearch = { closeAll, updateCount, getFiltriAttiviSearch, orderBy };
  const actionFilterDate = { setDateToChildren, checkFilter: checkFilterDateinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilterDate };
  const thActionDate = { orderBy };


  const downloadFile = async (filePath: string, fileName: string) => {
    let result = await DownloadProformaFile(filePath);
    if (result !== undefined) {
      let url = window.URL.createObjectURL(result.file);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName + ".pdf";
      a.click();
    }
  };
  const setProformaEmail = async (model: ProformaEmailViewModel, id: string) => {
    setProformaEmailState(model);
    setProformaId(id);
    setIsVisibleModalSendEmail(true)
  };
  const resendEmail = async (id: string) => {
    await ReSendEMail(id);
    await GetProformaViewModel({}).then(() => {
      setLoader("REMOVE", "GetProformaViewModel")
    });
  }





  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      

      {isVisibleModalSendEmail ? <>  <div className="col-12 px-0 mb-2">
        <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
          <h1 className="header">Proforma</h1>
          <ButtonActionIcon action={closeModal} icon={require("../../img/close.png")} />
        </div>
      </div>
        <SendEmailForm initialId={ProformaId ?? ""} email={ProformaEmail}></SendEmailForm>
      </>
        :
        <div className="col-12 mx-0 table-container">

          {props.isMobile ? <div className='row'>
            {props.data?.map((item, i) => <ProformaComponent key={item.id ?? "vabbe" + i + "asdasdas"} model={item as ProformaViewModel} action={{
              addProforma: function (data: ProformaViewModel) {
                props.action.Edit(item.id);

              }, removeProforma: () => props.action.onDelete(item.id),
              downloadFile,
              setProformaId: () => { setProformaId(item.id); setIsVisibleModalSendEmail(true) },
              setProformaEmail,
              resendEmail,
            }} />)}
          </div> :
            <table className="vma-table table   table-responsivetable-responsive table-sProformaed w-100 minHeight">
              <thead>
                <tr className="intestazione border-top">
                  {props.renderGrid?.sort((a, b) => a.order - b.order)?.filter((x) => x.show)?.map((item, i) =>
                    SelectFilterTypeGrid(
                      item.propertyName,
                      item.propertyText,
                      item.type,
                      props.query?.isSortAscending,
                      filtriAttivi,
                      actionFilterDate,
                      props.query?.sortBy,
                      filterData,
                      count,
                      actionFilterCheckbox,
                      thAction,
                      thActionDate,
                      isVisibleFiltriString,
                      actionFilterSearch
                    )
                  )}
                  <th className="gridHead px-0"  >
                    <div className="row mx-0 d-flex align-content-start">
                      <div className="px-2 w-100 d-flex align-items-start flex-row justify-content-between labelTh align-content-start">
                        <div className="relative">
                          <div className="filterSearch d-flex flex-row justify-content-between w-100">
                            Action
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!arrayIsNullOrEmpty(props.data) ? props.data?.map((item, i) => (
                  <tr className={`dati`} key={i}>
                    {props.renderGrid?.sort((a, b) => a.order - b.order)?.filter((x) => x.show)?.map((td, index) =>
                      td.propertyName === "email" ?
                        <td key={td.propertyName + index + "asd" + i} className={`dati ${td.colorHeader}`} >
                          {renderEmail(item)}
                        </td>
                        :
                        SelectGridType(item[td.propertyName], td.propertyName, td.type))}
                    <td className={`dati`}>
                      <div className="cell">
                        <div className="d-flex flex-row">
                          <button type="button" className="btn btn-link" onClick={() => props.action.Edit(item.id)}>
                            <img className="btnEdit" src={require("../../img/edit.png")} alt="edit" />
                          </button>
                          <ButtonActionIconGoogleIcon icon='outgoing_mail' action={() => { setProformaId(item.id); setIsVisibleModalSendEmail(true) }} />
                          <ButtonActionIconGoogleIcon icon='picture_as_pdf' action={() => {
                            downloadFile(item.filePath, item.fileName);
                          }} />

                          <button type="button" className="btn btn-link" onClick={() => props.action.onDelete(item.id)}>
                            <img className="btnEdit" src={require("../../img/delete.png")} alt="delete" />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>

                )) :
                  <tr>
                    <div className="w-100 my-2">
                      <h5>La ricerca non ha prodotto risultati</h5>
                    </div></tr>
                }
              </tbody>
            </table>
          }
        </div>
      }
    </div>

  );

  function renderEmail(item: ProformaViewModel) {
    return (
        <div className="cell row m-1">
            {item["email"]?.map((x) => (
                <div
                    className="col-12 email-cell p-2"
                    key={x.id}
                    style={{
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        marginBottom: "10px",
                        backgroundColor: x.sended ? "#f9f9f9" : "#fff8f8",
                    }}
                >
                    <div className="email-info mb-2">
                        <b className="email-address">{x.email}</b>
                        {x.sended ? (
                            <div className="email-status mt-1 text-success">
                                <b>Email inviata il:</b> {formatDateWithTimeformat(x.sendDate, "DD-MM-YYYY")}
                                <br />
                                <b>Aperto il:</b> {x.lastOpen ? formatDateWithTime(x.lastOpen) : "Mai aperto"}
                                <br />
                                <b>Download:</b> {x.downloadCount}
                            </div>
                        ) : (
                            <div className="email-status mt-1 text-danger">
                                <b>Email non inviata</b>
                            </div>
                        )}
                    </div>
                    <hr />
                    <div className="email-actions d-flex flex-row justify-content-between">
                        <ButtonActionIconGoogleIcon
                            className="action-button pl-1"
                            icon="outgoing_mail"
                            action={() => {
                                setProformaEmail(x, item.id);
                            }}
                        >
                            Modifica Email
                        </ButtonActionIconGoogleIcon>
                        <ButtonActionIconGoogleIcon
                            className="action-button"
                            icon="send"
                            action={() => {
                                resendEmail(x.id);
                            }}
                        >
                            Invia Email
                        </ButtonActionIconGoogleIcon>
                    </div>
                </div>
            ))}
        </div>
    );
}

}
export default ProformaGrid
