import React from "react";
import ReactDOM from "react-dom";

interface NotificationContainerProps {
    children: React.ReactNode;
}

const NotificationContainer: React.FC<NotificationContainerProps> = ({ children }) => {
    return ReactDOM.createPortal(
        <div
            className="notification-container"
            style={{
                position: "fixed",
                top: "15px",
                right: "15px",
                zIndex: 99999, // Altissimo per essere sopra le modali
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            {children}
        </div>,
        document.getElementById("notification-root")!
    );
};

export default NotificationContainer;
