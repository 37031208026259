import React from 'react'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import Icon from '../../../Components/Various/Icon'
import { GetProdottoViewModel } from '../../../Model/ProdottoModels'
import { VariantViewModel } from '../../../Model/VariantViewModel'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import { formatPriceWithLocale } from '../../../Business/Common/CommonBusiness'

interface Props {
  action?: {
    addVariant?(data: VariantViewModel,index : number): any | void,
    removeVariant?(data: VariantViewModel,index : number): any | void,
    chartBar?(data: VariantViewModel): any | void,
  }
  model: VariantViewModel,
  productName?: string,
  actionGridOverride?: (x?: any) => any;
  index:number
}
const VariantComponent: React.FC<Props> = (props) => {
 
  let t = props.model ;
  
let getProdottoViewModel: GetProdottoViewModel = props.model as any; 
getProdottoViewModel.name = props.productName ?? "";
console.log(props.model)
  return (
    <div className='col-12   mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><Icon icon={require("../../../img/bottle-wine.png")} height={30} /><label>{props.productName}</label></div>
          <div className='float-right mt-3 mr-1 row'>
            {props.action && props.action.chartBar &&<div className='col mr-0'>  <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action && props.action.chartBar && props.action.chartBar(props.model)}  /></div>}
            {props.actionGridOverride && <div className='col mr-0'>  <ButtonActionIcon icon={require("../../../img/add.png")} height={15} action={()=>props.actionGridOverride&& props.actionGridOverride(getProdottoViewModel)} /></div>}
            {props.action && props.action.addVariant && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addVariant && props.action.addVariant(props.model,props.index)} /></div>}
            {props.action && props.action.removeVariant && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeVariant && props.action.removeVariant(props.model,props.index)} /></div>}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-md-6'>{props.model.code ? <label ><Icon icon={require("../../../img/barcode.png")} /> {props.model.code}</label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.quantita ? <label ><Icon icon={require("../../../img/inventory_black.png")} /> {props.model.quantita}</label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.sellValue ? <label ><Icon icon={require("../../../img/euro.png")} />prezzo vendita: {formatPriceWithLocale(props.model.sellValue)}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.buyValue ? <label ><Icon icon={require("../../../img/euro.png")} />prezzo acquisto: {formatPriceWithLocale(props.model.buyValue)}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.size ? <label ><Icon icon={require("../../../img/outline_grade_black_24dp.png")} />  {props.model.size}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.azienda ? <label ><Icon icon={require("../../../img/store.png")} /> {props.model.azienda} </label> : null} </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VariantComponent