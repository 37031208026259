import React, { useState } from "react";
import '../../Css/NavMenu.css'
import { Link } from 'react-router-dom';
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIconTextHoriz from "../Various/ButtonActionIconTextHoriz";
import logout from '../../img/logout.png'
import { ClearLocalForge } from "../../Business/Store/CommonStore";
import { removeAccessToken } from "../../Redux/Action/AuthenticationAction";



const NavLinkList: React.FC = (props) => {

  const clearAndLogout = () => {
    ClearLocalForge(false);
    localStorage.removeItem("projectCode");
    removeAccessToken();
  }

  const [isSubMenuOpenUser, setSubMenuOpenUser] = useState(false);
  const [isSubMenuOpenProduct, setSubMenuOpenProduct] = useState(false);
  const [isSubMenuOpenVehicle, setSubMenuOpenVehicle] = useState(false);

  const toggleSubMenuVehicle = () => {
    setSubMenuOpenVehicle(!isSubMenuOpenVehicle);
  };
  const toggleSubMenuProduct = () => {
    setSubMenuOpenProduct(!isSubMenuOpenProduct);
  };
  const toggleSubMenuUser = () => {
    setSubMenuOpenUser(!isSubMenuOpenUser);
  };
  return (
    <div style={{ height: "100vh" }}>

      <div className="navbarSidebar-item">
        <Link to="#" className="text-white" onClick={toggleSubMenuProduct}>
          <MaterialIcon icon="shelves" size={46} />
          <label className="navLabel text-nowrap">Gestione Prodotti</label>
          <MaterialIcon icon={isSubMenuOpenProduct ? "expand_less" : "expand_more"} size={46} />
        </Link>
      </div>

      {isSubMenuOpenProduct && (
        <div className="subMenu">

          <div className="navbarSidebar-item  ">
            <Link to="/Prodotto" className="text-white">
              <MaterialIcon icon="wine_bar" size={46} />
              <label className="navLabel text-nowrap">Prodotti</label>
            </Link>
          </div>
          <div className="navbarSidebar-item">
            <Link to="/Taglie" className="text-white">
              <MaterialIcon icon="grade" size={46} />
              <label className="navLabel text-nowrap">Taglie</label>
            </Link>
          </div>
        </div>
      )}


      <div className="navbarSidebar-item">
        <Link to="#" className="text-white" onClick={toggleSubMenuVehicle}>
          <MaterialIcon icon="warehouse" size={46} />
          <label className="navLabel text-nowrap">Gestione Veicoli e viaggi</label>
          <MaterialIcon icon={isSubMenuOpenVehicle ? "expand_less" : "expand_more"} size={46} />

        </Link>
      </div>

      {isSubMenuOpenVehicle && (
        <div className="subMenu">
          <div className="navbarSidebar-item">
            <Link to="/Veicoli" className="text-white">
              <MaterialIcon icon="local_shipping" size={46} />
              <label className="navLabel text-nowrap">Lista veicoli</label>
            </Link>
          </div>
          <div className="navbarSidebar-item">
            <Link to="/Viaggi" className="text-white">
              <MaterialIcon icon="add_road" size={46} />
              <label className="navLabel text-nowrap">Lista viaggi</label>
            </Link>
          </div>
        </div>
      )}

      <div className="navbarSidebar-item">
        <Link to="/Movimentazione" className="text-white">
          <MaterialIcon icon="forklift" size={46} />
          <label className="navLabel text-nowrap">Movimentazioni</label>
        </Link>
      </div>
      <div className="navbarSidebar-item">
        <Link to="/Proforma" className="text-white">
          <MaterialIcon icon="money" size={46} />
          <label className="navLabel text-nowrap">Proforma</label>
        </Link>
      </div>
      <div className="navbarSidebar-item">
        <Link to="/Azienda" className="text-white">
          <MaterialIcon icon="store" size={46} />
          <label className="navLabel text-nowrap">Lista Aziende</label>
        </Link>
      </div>






      {/* <div className="navbarSidebar-item">
        <Link to="#" className="text-white" onClick={toggleSubMenuUser}>
          <MaterialIcon icon="person" size={46} />
          <label className="navLabel text-nowrap">Gestione Utenti e Ruoli</label>
          <MaterialIcon icon={isSubMenuOpenUser ? "expand_less" : "expand_more"} size={46} />

        </Link>
      </div> */}
      {/* 
      {isSubMenuOpenUser && (
        <div className="subMenu">
          <div className="navbarSidebar-item">
            <Link to="/User" className="text-white">
              <MaterialIcon icon="person" size={46} />
              <label className="navLabel text-nowrap">Lista Utenti</label>
            </Link>
          </div>
          <div className="navbarSidebar-item">
            <Link to="/RoleList" className="text-white">
              <MaterialIcon icon="supervisor_account" size={46} />
              <label className="navLabel text-nowrap">Lista Ruoli</label>
            </Link>
          </div>
        </div>
      )} */}

      <div className="navbarSidebar-item">
        <Link to="/User" className="text-white">
          <MaterialIcon icon="person" size={46} />
          <label className="navLabel text-nowrap">Lista Utenti</label>
        </Link>
      </div>


      <div className="navbarSidebar-item">
        <Link to="#" onClick={() => clearAndLogout()} className="text-white">
          <MaterialIcon icon="logout" size={46} />
          <label className="navLabel text-nowrap">Logout</label>
        </Link>
        {/* <Link to="/Settings" className="text-white">
          <MaterialIcon icon="settings" size={46} />
          <label className="navLabel text-nowrap">Impostazioni</label>
        </Link> */}
      </div>
      {/* <div className="navbarSidebar-item">
        <Link to="/barcode">
          <img src={require('../../svg/settings_W.png')} alt="settings"></img>
          <label className="navLabel text-nowrap">Impostazioni</label>
        </Link>
      </div> */}

    </div>
  )
}

export default NavLinkList
