import React from 'react';
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon';
import Icon from '../../../Components/Various/Icon';

const LinearMovimentazioneVariant = (props) => {
  const { productVariant } = props.model;
  const totalCost = (props.model.price || 0) * (props.model.invoiceQuantity || 0);

  return (
    <div className="linear-movimentazione-variant">
      {/* Sezione Superiore: Informazioni del Prodotto */}
      <div className="product-info">
        <div className="product-header">
          <Icon icon={require("../../../img/bottle-wine.png")} height={20} />
          <span className="product-name">{props.productName}</span>
        </div>
        <div className="product-details">
          {productVariant.code && (
            <div className="detail">
              <Icon icon={require("../../../img/barcode.png")} /> Codice: {productVariant.code}
            </div>
          )}
          {productVariant.size && (
            <div className="detail">
              <Icon icon={require("../../../img/outline_grade_black_24dp.png")} /> Taglia: {productVariant.size}
            </div>
          )}
          {productVariant.azienda && (
            <div className="detail">
              <Icon icon={require("../../../img/store.png")} /> Azienda: {productVariant.azienda}
            </div>
          )}
        </div>
      </div>

      {/* Sezione Inferiore: Prezzo, Quantità e Costo Totale */}
      <div className="product-pricing">
        <div className="pricing-row">
          <div className="pricing-detail">
            <Icon icon={require("../../../img/inventory_black.png")} /> Quantità: {props.model.invoiceQuantity || 0}
          </div>
          <div className="pricing-detail">
            <Icon icon={require("../../../img/euro.png")} /> Prezzo: {props.model.price || 0} €
          </div>
          <div className="pricing-detail total-cost">
            <Icon icon={require("../../../img/euro.png")} /> Costo Totale: {totalCost.toFixed(2)} €
          </div>
        </div>
      </div>

      {/* Azioni */}
      <div className="actions">
        {props.action?.removeVariant && (
          <ButtonActionIcon
            icon={require("../../../img/delete.png")}
            height={15}
            action={() => props.action.removeVariant(props.model)}
          />
        )}
        {props.action?.addProduct && (
          <ButtonActionIcon
            icon={require("../../../img/add.png")}
            height={15}
            action={() => props.action.addProduct(props.model)}
          />
        )}
        {props.action?.editProduct && (
          <ButtonActionIcon
            icon={require("../../../img/edit.png")}
            height={15}
            action={() => props.action.editProduct(props.model)}
          />
        )}
      </div>
    </div>
  );
};

export default LinearMovimentazioneVariant;
