import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import { CommonValidation } from '../../Business/Common/CommonBusiness';
import DatePickerComponent from '../../Components/FormAndFilters/DatePickerComponent';
import InputPlaceComponent from '../../Components/FormAndFilters/InputPlaceComponent';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import SelectComponent from '../../Components/FormAndFilters/SelectComponent';
import MappaConMarkerTrascinabile from '../../Components/MappaMarker';
import '../../Css/AziendaList.css';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { VehicleGps, VehicleViewModel, VehicleViewModelResource } from '../../Model/VehicleModels';
import { CreateVehicle } from '../../Redux/Action/Vehicle/VehicleCreateAction';
import { UpdateVehicle } from '../../Redux/Action/Vehicle/VehicleEditAction';
import { RootState } from '../../Redux/Store/rootStore';

interface Props {
  action: {
    closeModal(changed: boolean): any | void
    onCreate?: (data: VehicleViewModelResource) => void
  }
  isVisibleModal: boolean
  edit: boolean
  id?: string
}

const VehicleModal: React.FC<Props> = (props) => {



  useEffect(() => {

  }, [])


  const validazioneClient = (formData: VehicleViewModelResource | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };


    if (stringIsNullOrEmpty(formData?.model))
      addInvalidProperty("model");

    if (stringIsNullOrEmpty(formData?.licensePlate))
      addInvalidProperty("licensePlate");


    setValidation(copyValidation);
    return copyValidation;

  }

  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
  };

  const darkTheme = useContext(DarkTheme)

  const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeDate, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<VehicleViewModelResource>(
    CreateVehicle,
    UpdateVehicle,
    props.action.onCreate
  );

  const dtoEditResourceState = (state: RootState) => state.VehicleCreateReducer.dto;
  let editResource = useSelector(dtoEditResourceState);

  useEffect(() => {
    if (editResource) {
      const copy = { ...editResource };
      setFormData(copy);
    }
  }, [editResource]);
 



  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <form id="form" className='w-100' onChange={() => setChanged(true)}>
        <div className="row mx-0 col-12 px-0">
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.model} name={'model'} title={'Modello'} type={'text'} validation={validation} validationMessage={'Modello obbligatorio'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.licensePlate} name={'licensePlate'} title={'Targa'} type={'text'} validation={validation} validationMessage={'Targa obbligatorio'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.status} name={'status'} title={'Status'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <DatePickerComponent onChange={onChangeDate} value={formData?.lastMaintenanceDate} name={'lastMaintenanceDate'} title={'Ultima manutenzione'} />
          </div>
          <div className="col-12 col-md-6">
            <DatePickerComponent onChange={onChangeDate} value={formData?.nextMaintenanceDate} name={'nextMaintenanceDate'} title={'Prossima manutenzione'} />
          </div>
          {/* <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.currentLocation} name={'currentLocation'} title={'Luogo'}  type={'text'}   />
          </div>   */}
          <div className="col-12 col-md-6">
            <InputPlaceComponent onChange={onChange} value={formData?.currentLocation} name={'currentLocation'} title={'Luogo'} type={'text'} />
            <MappaConMarkerTrascinabile
              // googleMapsApiKey="AIzaSyBs_XCG7dgJ2zRlyJFxV3oWDx-ijDyKVto"
              indirizzoIniziale={formData?.currentLocation ?? ""}
              onIndirizzoCambiato={(e) => {
                onChange("currentLocation", { currentTarget: { value: e } } as unknown as Event)

              }}

            />
          </div>
     

          <div className="col-12 col-md-6">
            <InputTextComponent onChange={(name, e) => {
              let copy = { ...formData };
              if (copy != undefined) {
                copy.gpsInfo ??= {} as VehicleGps;
                copy.gpsInfo.gpsIdentifier = e.currentTarget.value
              }
              setFormData(copy as VehicleViewModel);
            }} value={formData?.gpsInfo?.gpsIdentifier} name={'gpsInfo.gpsIdentifier'} title={'Gps Id'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={(name, e) => {
              let copy = { ...formData };
              if (copy != undefined) {
                copy.gpsInfo ??= {} as VehicleGps;
                copy.gpsInfo.gpsPhoneNumber = e.currentTarget.value
              }
              setFormData(copy as VehicleViewModel);
            }} value={formData?.gpsInfo?.gpsPhoneNumber} name={'gpsInfo.gpsPhoneNumber'} title={'Numero associato al Gps'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={(name, e) => {
              let copy = { ...formData };
              if (copy != undefined) {
                copy.gpsInfo ??= {} as VehicleGps;
                copy.gpsInfo.siteGps = e.currentTarget.value
              }
              setFormData(copy as VehicleViewModel);
            }} value={formData?.gpsInfo?.siteGps} name={'gpsInfo.siteGps'} title={'Sito del Gps'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={(name, e) => {
              let copy = { ...formData };
              if (copy != undefined) {
                copy.gpsInfo ??= {} as VehicleGps;
                copy.gpsInfo.userName = e.currentTarget.value
              }
              setFormData(copy as VehicleViewModel);
            }} value={formData?.gpsInfo?.userName} name={'gpsInfo.userName'} title={'Credenziali: Username Sito GPS'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={(name, e) => {
              let copy = { ...formData };
              if (copy != undefined) {
                copy.gpsInfo ??= {} as VehicleGps;
                copy.gpsInfo.password = e.currentTarget.value
              }
              setFormData(copy as VehicleViewModel);
            }} value={formData?.gpsInfo?.password} name={'gpsInfo.password'} title={'Credenziali: Password Sito GPS'} type={'text'} />
          </div>
        </div>
        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
          <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
            ANNULLA
          </button>
          <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, (e) => { refresh(false); })} type="button">
            SALVA
          </button>
        </div>
      </form>


    </div>
  );
}
export default VehicleModal





