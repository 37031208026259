import { BarCodeViewModel } from "./BarCode";
import { CustomGridRender, FilterValueDto, QueryObject, RenderDetail, ResultDto } from "./CommonModel";
import { VariantViewModel } from "./VariantViewModel";
export enum SellBuyViewModel {
  BUY = "BUY",
  SELL = "SELL"
}

export interface MovimentazioneChart {
  price: number;
  date: string;
  quantity: number;
}
export interface GetProdottoViewModel {
  id: string;
  variantId: string;
  prodottoId: string;
  aziendaId: string | null;
  type: string | null;
  sizeId: string | null;
  name: string;
  quantita: number;
  prodottiXCollo: number;
  sellValue: number | null;
  buyValue: number | null;
  code: string | null;
  image: string | null;
  size: string | null;
  azienda: string | null;
  creaInDatabase : boolean;
  invoiceQuantity?: number | null | undefined;
  invoicePrice?: number | null | undefined;
  productName?: string | null;
}
/**
 * 
 * @export
 * @interface AttributeViewModel
 */
export interface AttributeViewModel {
  /**
   * 
   * @type {string}
   * @memberof AttributeViewModel
   */
  id?: string;
  /**
   * 
   * @type {string}
   * @memberof AttributeViewModel
   */
  name?: string;
  /**
   * 
   * @type {string}
   * @memberof AttributeViewModel
   */
  value?: string;
}
/**
 * 
 * @export
 * @interface PriceViewModel
 */
export interface PriceViewModel {
  /**
   * 
   * @type {string}
   * @memberof PriceViewModel
   */
  id?: string;
  /**
   * 
   * @type {number}
   * @memberof PriceViewModel
   */
  value?: number;
  /**
   * 
   * @type {string}
   * @memberof PriceViewModel
   */
  prodottoId?: string;
  /**
   * 
   * @type {string}
   * @memberof PriceViewModel
   */
  aziendaId?: string;
  /**
   * 
   * @type {number}
   * @memberof PriceViewModel
   */
  sellBuy?: number;
}
/**
* 
* @export
* @interface ProdottoViewModel
*/
export interface ProdottoViewModel {
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModel
   */
  id?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModel
   */
  name?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModel
   */
  description?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModel
   */
  image?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModel
   */
  imagePath?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModel
   */
  categoriaId?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModel
   */
  categoria?: string;
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModel
   */
  price?: number;
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModel
   */
  quantita?: number;
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModel
   */
  quantitaTot?: number;
}
/**
* 
* @export
* @interface ProdottoViewModelCustomGridRender
*/

/**
* 
* @export
* @interface ProdottoViewModelQuery
*/
export interface ProdottoViewModelQuery {
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelQuery
   */
  lang?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelQuery
   */
  sortBy?: string;
  /**
   * 
   * @type {boolean}
   * @memberof ProdottoViewModelQuery
   */
  isSortAscending?: boolean;
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModelQuery
   */
  page?: number;
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModelQuery
   */
  pageSize?: number;
  /**
   * 
   * @type {Array<string>}
   * @memberof ProdottoViewModelQuery
   */
  name?: Array<string>;
  /**
   * 
   * @type {Array<string>}
   * @memberof ProdottoViewModelQuery
   */
  description?: Array<string>;
  quantita?: number | null;
  sellValue?: number | null;
  buyValue?: number | null;
  code?: string[] | null;
  size?: string[] | null;
  azienda?: string[] | null;
}



/**
* 
* @export
* @interface ProdottoViewModelQueryResultResponse
*/
export interface ProdottoQueryResultDto {
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<ProdottoViewModel>}
   * @memberof ProdottoViewModelQueryResultResponse
   */
  items?: Array<ProdottoViewModel>;
  /**
   * 
   * @type {ProdottoViewModelCustomGridRender}
   * @memberof ProdottoViewModelQueryResultResponse
   */
  gridRender?: RenderDetail[];
}

/**
* 
* @export
* @interface ProdottoViewModelResource
*/
export interface ProdottoViewModelResource {
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelResource
   */
  id?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelResource
   */
  name?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelResource
   */
  description?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelResource
   */
  image?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelResource
   */
  imagePath?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelResource
   */
  categoriaId?: string;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelResource
   */
  categoria?: string;
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModelResource
   */
  price?: number;
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModelResource
   */
  quantita?: number;
  /**
   * 
   * @type {number}
   * @memberof ProdottoViewModelResource
   */
  quantitaTot?: number;
  /**
   * 
   * @type {Array<VariantViewModel>}
   * @memberof ProdottoViewModelResource
   */
  variantViewModel?: Array<VariantViewModel>
  /**
   * 
   * @type {Array<AttributeViewModel>}
   * @memberof ProdottoViewModelResource
   */
  attributes?: Array<AttributeViewModel>;
}
/**
 * 
 * @export
 * @interface AziendaViewModelProdotto
 */
export interface AziendaViewModelProdotto extends AziendaViewModel {

  /**
   * 
   * @type {number}
   * @memberof AziendaViewModelProdotto
   */
  quantita?: number;
}
/**
* 
* @export
* @interface ProdottoViewModelResourceResponseResult
*/
export interface ProdottoViewModelResourceResponseResult {
  /**
   * 
   * @type {boolean}
   * @memberof ProdottoViewModelResourceResponseResult
   */
  warning?: boolean;
  /**
   * 
   * @type {string}
   * @memberof ProdottoViewModelResourceResponseResult
   */
  info?: string;
  /**
   * 
   * @type {ProdottoViewModelResource}
   * @memberof ProdottoViewModelResourceResponseResult
   */
  data?: ProdottoViewModelResource;
}
export interface ProdottoViewModelGrid {
  gridResult: ProdottoListQueryResultDto | null
  filter: FilterValueDto[] | null
}


export interface ProdottoModelDetail {
  dtoEdit: ProdottoViewModelResource | null,
  resultDto: ResultDto<null> | null,
}

export interface ProdottoCreate {
  dto: ProdottoViewModelResource | null,
  resultDto: ResultDto<null> | null,
}
export interface ProdottoEdit extends ProdottoCreate {

}
export interface ProdottoModelDownloaded {
  dtoDownload: number[],
}

export interface ProdottoListQueryResultDto {
  /**
   *
   * @type {number}
   * @memberof ProdottoListQueryResultDto
   */
  totalItems?: number;
  /**
   *
   * @type {Array<ProdottoList>}
   * @memberof ProdottoListQueryResultDto
   */
  items?: Array<GetProdottoViewModel>;
  /**
   *
   * @type {CustomGridRender}
   * @memberof ProdottoListQueryResultDto
   */
  gridRender?: CustomGridRender;
}export interface AziendaViewModel {
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  id?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  name?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  description?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  image?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  imagePath?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  location?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  phone?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  email?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  website?: string;
  /**
   * 
   * @type {string}
   * @memberof AziendaViewModelProdotto
   */
  piva?: string;
}

export const GET_Prodotto_GRID = "GET_Prodotto_GRID"
export const ADD_Prodotto_GRID = "ADD_Prodotto_GRID"
export const GET_Prodotto_FILTERS = "GET_Prodotto_FILTERS"
export const GET_Prodotto_DETAIL = "GET_Prodotto_DETAIL"
export const GET_EDIT_Prodotto = "GET_EDIT_Prodotto"
export const EDIT_Prodotto = "EDIT_Prodotto"
export const CREATE_Prodotto = "CREATE_Prodotto"
export const GET_CREATE_Prodotto = "GET_CREATE_Prodotto"
export const DELETE_Prodotto = "DELETE_Prodotto"


//#region offline definition

export const Prodotto_LOCAL = "Prodotto_LOCAL"
export const Prodotto_FILTERS_LOCAL = "Prodotto_FILTERS_LOCAL"

export const Prodotto_GRIDRENDER = "Prodotto_GRIDRENDER"

//#endregion
