import React, { useEffect, useRef, useState } from 'react';
import CheckboxComponent from '../../../Components/FormAndFilters/CheckboxComponent';
import InputNumberComponent from '../../../Components/FormAndFilters/InputNumberComponent';
import InputTextComponent from '../../../Components/FormAndFilters/InputTextComponent';
import SelectComponent from '../../../Components/FormAndFilters/SelectComponent';
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon';
import { GetProdottoMovimentazione } from '../../../Redux/Action/Movimentazione/MovimentazioneGridAction';
import MovimentazioneVariant from '../Variant/MovimentazioneVariant';


const MovimentazioneFormComponent = ({
  i,
  x,
  removeMovimentazioneVariantCanvas,
  onchangeMovimentazioneVariantCanvas,
  onchangeMovimentazioneVariantCanvascreaInDatabase,
  GetProductSizeCanvasValue,
  ProductSizeResource,
  SetProductSizeCanvasSelect,
  GetProductAziendaCanvasValue,
  AziendeResource,
  SetProductAziendaCanvasSelect,
  formData,
  returnProduct
}) => {


  const isUpdating = useRef(false);

  useEffect(() => {
    const updateModel = async () => {
      if (isUpdating.current) {
        return; // Prevents the loop by exiting if already updating
      }

      if (x.productVariant?.name && x.productVariant?.aziendaId && x.productVariant?.sizeId) {
        isUpdating.current = true;
        console.log('Updating model due to changes in name or aziendaId');
        const tes = await GetProdottoMovimentazione(
          x.productVariant.name,
          x.productVariant.aziendaId,
          x.productVariant.sizeId
        );

        if (tes.warning === false) {
          returnProduct(tes.data, i);
        }

        isUpdating.current = false;
      }
    };

    updateModel();
    // No cleanup function is needed here
  }, [x.productVariant.name, x.productVariant.aziendaId, x.productVariant.sizeId]);
  const calculateTotal = () => {
    const unitPrice = x.productVariant.invoicePrice ?? x.price;
    const totalInWarehouse = x?.productVariant.invoiceQuantity ?? 0
    return unitPrice * (totalInWarehouse);
  };
  return (
    <MovimentazioneVariant key={"daii" + i} model={x} productName={x.productVariant.name}

      action={{
        removeVariant(data) {
          removeMovimentazioneVariantCanvas(i);
        },
      }}
    >
      <>
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "15px",
            fontSize: "1rem",
            fontWeight: "bold",
            backgroundColor: "#f8f9fa",
            padding: "5px 10px",
            borderRadius: "5px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1,
          }}
        >
          Totale: €{calculateTotal().toFixed(2)}
        </div>

        <div className='col-12  '>
          <InputTextComponent type='text' value={x.productVariant.name} onChange={(n, e) => onchangeMovimentazioneVariantCanvas(x, e, "productName", i)} name={'productName'} title={'Nome'} />
        </div>

        <div className='col-12 col-lg-6'>
          <InputNumberComponent value={x.productVariant.invoicePrice ?? x.price} onChange={(n, e) => onchangeMovimentazioneVariantCanvas(x, e, "price", i)} name={'price'} title={'Price'} />
        </div>
        <div className='col-12 col-lg-6'>
          <InputNumberComponent value={x.productVariant.invoiceQuantity } onChange={(n, e) => onchangeMovimentazioneVariantCanvas(x, e, "quantity", i)} name={'quantity'} title={'Quantity'} />
        </div>
        <div className='col-12 col-lg-6'>
          {(x.productVariantId == null || x.productVariantId === undefined || x.productId == null || x.productId === undefined) ? <CheckboxComponent
            value={x.creaInDatabase}
            onChange={() => onchangeMovimentazioneVariantCanvascreaInDatabase(i, !x.creaInDatabase)}
            name={"crea"}
            title={x.productId == null || x.productId === undefined ? "crea prodotto e variante" : "☑️ prodotto presente in database crea variante"}
          ></CheckboxComponent> : <label>✅ presente in database</label>}
        </div>
        <div className='col-12'>
          <SelectComponent
            value={GetProductSizeCanvasValue(i)}
            options={ProductSizeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" }; })}
            onChange={(e) => SetProductSizeCanvasSelect(e, i)}
            name={'sizeId'}
            title={'Size'}
            isMulti={false}
            modal={{ modalType: "ProductSize" }} />
        </div>

        <div className='col-12'>
          <SelectComponent
            value={GetProductAziendaCanvasValue(i)}
            options={AziendeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" }; })}
            onChange={(e) => SetProductAziendaCanvasSelect(e, i)}
            name={'aziendaId'}
            title={'Azienda'}
            isMulti={false}
            modal={{ modalType: "Azienda" }} />
        </div>

      </>
    </MovimentazioneVariant>
  );
};

export default MovimentazioneFormComponent;
