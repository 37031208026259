import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { PdfToImageFile } from "../Business/image/PdfUtils";
import { GetHeader, basePath } from "../Business/Common/configuration";
import { ProcessImageRequestSave } from "../Model/InvoiceAreasModel";
import { AddInvoicesArea, GetInvoicesArea, DeleteInvoicesArea } from "../Redux/Action/InvoiceAreas/InvoicesAreaAction";
import SelectComponent from "./FormAndFilters/SelectComponent";
import InputTextComponent from "./FormAndFilters/InputTextComponent";
import { GetProdottoViewModel } from "../Model/ProdottoModels";
import { rootStore } from "../Redux/Store/rootStore";
import { setNotification } from "../Redux/Action/NotificationAction";
import { OcrMovimentazione } from "../Redux/Action/Movimentazione/MovimentazioneCreateAction";
import { NotifyType } from "../Redux/Reducer/NotificationReducer";
import setLoader from "../Redux/Action/LoaderAction";
import Select, { GroupBase } from "react-select";

interface Props {
  AddFromCanvas: (x: GetProdottoViewModel[], azienda: any, aziendaSeller: any, aziendaBuyer: any, date: Date, file: File,numeroFattura :string | null) => void
  operation: "In" | "Out" | number;
  azienda : string | null | undefined
}
export interface CanvasComponentMethods {
  externalMethod: () => void;
}
const CanvasComponent = forwardRef<CanvasComponentMethods, Props>((props, ref) => {




  const [selectionsData, setSelectionsData] = useState<{ x: number; y: number; width: number; height: number }>({} as { x: number; y: number; width: number; height: number });
  const [selectionsAziendaName, setSelectionsAziendaName] = useState<{ x: number; y: number; width: number; height: number }>({} as { x: number; y: number; width: number; height: number });
  const [selectionsAziendaPiva, setSelectionsAziendaPiva] = useState<{ x: number; y: number; width: number; height: number }>({} as { x: number; y: number; width: number; height: number });
  const [selectionsAziendaMail, setSelectionsAziendaMail] = useState<{ x: number; y: number; width: number; height: number }>({} as { x: number; y: number; width: number; height: number });
  const [selectionsAziendaLocation, setSelectionsAziendaLocation] = useState<{ x: number; y: number; width: number; height: number }>({} as { x: number; y: number; width: number; height: number });
  const [selectionsNumeroFattura, setSelectionsNumeroFattura] = useState<{ x: number; y: number; width: number; height: number }>({} as { x: number; y: number; width: number; height: number });

  const [selectionsRighe, setSelectionsRighe] = useState<{ x: number; y: number; width: number; height: number }>({} as { x: number; y: number; width: number; height: number });
  const [selectionsRigheQuantità, setSelectionsRigheQuantità] = useState<{ x: number; y: number; width: number; height: number }>({} as { x: number; y: number; width: number; height: number });

  const [selectionsRighePrezzo, setSelectionsRighePrezzo] = useState<
    { x: number; y: number; width: number; height: number }
  >({} as { x: number; y: number; width: number; height: number });
  const [selectionsRigheProdotto, setSelectionsRigheProdotto] = useState<
    { x: number; y: number; width: number; height: number }
  >({} as { x: number; y: number; width: number; height: number });

  const [, updateState] = useState<Date>();
  const forceUpdate = () => updateState(new Date());
  const [listInvoice, setListInvoice] = useState<ProcessImageRequestSave[]>([])
  const [invoiceAreas, setInvoiceAreas] = useState<ProcessImageRequestSave>()

  const [selector, setSelector] = useState<"data" | "righe" | "Quantità" | "Prezzo" | "Prodotto" | "AziendaName" | "AziendaMail" | "AziendaLocation" | "AziendaPiva" | "NumeroFattura" | "">("");
  const [height, setHeight] = useState(0);
  const [start, setStart] = useState(false);

  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endX, setEndX] = useState(0);
  const [endY, setEndY] = useState(0);
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [azienda, setAzienda] = useState<any>({})
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const [imageData, setImageData] = useState<HTMLImageElement | null>(null);
  // if (canvasRef && canvasRef.current && imageData) {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   if (ctx) {
  //     // ctx.drawImage(imageData, 0, 0, canvas.width, canvas.height);
  //     DrawSection(ctx, selectionsData, "red", "Data Fattura");
  //     DrawSection(ctx, selectionsAziendaName, "orange", "Azienda Name");
  //     DrawSection(ctx, selectionsAziendaPiva, "purple", "Azienda PIVA");
  //     DrawSection(ctx, selectionsAziendaMail, "green", "Azienda Mail");
  //     DrawSection(ctx, selectionsAziendaLocation, "gray", "Azienda Location");
  //     DrawSection(ctx, selectionsRigheQuantità, "green", "Quantità");
  //     DrawSection(ctx, selectionsRighePrezzo, "purple", "Prezzo");
  //     DrawSection(ctx, selectionsRigheProdotto, "orange", "Prodotto");

  //   }
  // }

  useEffect(() => {
    setAzienda(props.azienda);
    console.log("🥲",props.azienda)
  }, [props.azienda])
  

  useEffect(() => {
    if (canvasRef && canvasRef.current && imageData) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        DrawSection(ctx, selectionsData, "red", "Data Fattura");
        DrawSection(ctx, selectionsAziendaName, "orange", "Azienda Name");
        DrawSection(ctx, selectionsAziendaPiva, "purple", "Azienda PIVA");
        DrawSection(ctx, selectionsAziendaMail, "green", "Azienda Mail");
        DrawSection(ctx, selectionsAziendaLocation, "gray", "Azienda Location");
        DrawSection(ctx, selectionsRigheQuantità, "green", "Quantità");
        DrawSection(ctx, selectionsRighePrezzo, "purple", "Prezzo");
        DrawSection(ctx, selectionsRigheProdotto, "orange", "Prodotto");
        DrawSection(ctx, selectionsNumeroFattura, "black", "NumeroFattura");
        ctx.save();
      }
    }

  }, [start, imageData, selectionsData, selectionsAziendaName, selectionsAziendaPiva, selectionsAziendaMail, selectionsAziendaLocation, selectionsRigheQuantità, selectionsRighePrezzo, selectionsRigheProdotto, selectionsNumeroFattura])
  useEffect(() => {
    if (imageData && imageCanvasRef.current) {
      const canvas = imageCanvasRef.current;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(imageData, 0, 0, canvas.width, canvas.height);
      }
    }
  }, [imageData]);
  const sendImageAutoTuning = async (localFile) => {
    const formData = new FormData();
    if (localFile) {
      formData.append("Invoice", localFile, localFile?.name);
      formData.append("operation", props.operation.toString());
      let response = await fetch(basePath + "Movimentazione/sendImageAutoTuning", {
        method: "POST",
        body: formData,
        headers: await GetHeader(true)
      });
      var result = await response.json();
      if (result.data?.id !== undefined && result.data?.id != null) {
        await SetInvoiceAreasSelect({ value: result.data.id }); // setInvoiceArea(result);
        await sendSelectionsFromName(localFile, result.data.name);
      }
    }
  }

  const sendSelectionsFromName = async (localFile, name) => {
    let response = await OcrMovimentazione(localFile, name, props.operation.toString());
    await returnByOcr(response, localFile);

  }

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let localFile: File | null = null;
    if (event.target.files && event.target.files[0]) {
      setLoader("ADD", "imageChange")
      if (event.target.files[0] && event.target.files[0].type === 'application/pdf') {

        let fileFromPdf = await PdfToImageFile(event.target.files[0]);
        setFile(fileFromPdf);
        localFile = fileFromPdf;
      } else {
        setFile(event.target.files[0]);
        localFile = event.target.files[0];
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        setImage(e.target.result);
        const img = new Image();
        img.src = e.target.result as string;
        img.onload = () => setImageData(img);
      };
      reader.readAsDataURL(localFile);
      await sendImageAutoTuning(localFile);
      setLoader("REMOVE", "imageChange")

    }
  };


  useEffect(() => {
    (async () => {
      const invoiceAreas = await GetInvoicesArea();
      setListInvoice(invoiceAreas);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  useEffect(() => {
    if (imageData && containerRef.current?.clientWidth) {
      const ctx = imageCanvasRef?.current?.getContext("2d");
      let rapportoImage = (imageData.height / imageData.width) * 100;
      let h = (containerRef.current.clientWidth / 100) * rapportoImage;
      // const ratio = (containerRef.current.clientWidth / imageData.width) / (height / imageData.height); // calcola il rapporto di dimensioni tra le due immagini

      setHeight(h);
      if (ctx) {

        ctx.clearRect(0, 0, imageCanvasRef?.current?.width ?? 0, imageCanvasRef?.current?.height ?? 0);

        ctx.drawImage(
          imageData,
          0,
          0,
          imageCanvasRef?.current?.width ?? 0,
          imageCanvasRef?.current?.height ?? 0
        );
      }
    }
  }, [imageData]);
  useEffect(() => {
    if (height !== 0 && imageData) {
      const ctx = imageCanvasRef?.current?.getContext("2d");
      if (ctx) {
        ctx.drawImage(
          imageData,
          0,
          0,
          imageCanvasRef?.current?.width ?? 0,
          imageCanvasRef?.current?.height ?? 0
        );
      }
    }
  }, [height]);


  useEffect(() => {

    if (invoiceAreas) {
      let selection = invoiceAreas;

      selection.dataAreas && setSelectionsData(GetUnscaleValue(JSON.parse(selection.dataAreas)));

      selection.rigaQuantitàAreas && setSelectionsRigheQuantità(GetUnscaleValue(JSON.parse(selection.rigaQuantitàAreas)));

      selection.rigaPrezzoAreas && setSelectionsRighePrezzo(GetUnscaleValue(JSON.parse(selection.rigaPrezzoAreas)));

      selection.rigaProdottoAreas && setSelectionsRigheProdotto(GetUnscaleValue(JSON.parse(selection.rigaProdottoAreas)));

      selection.aziendaNameAreas && setSelectionsAziendaName(GetUnscaleValue(JSON.parse(selection.aziendaNameAreas)));

      selection.aziendaPivaAreas && setSelectionsAziendaPiva(GetUnscaleValue(JSON.parse(selection.aziendaPivaAreas)));

      selection.aziendaMailAreas && setSelectionsAziendaMail(GetUnscaleValue(JSON.parse(selection.aziendaMailAreas)));

      selection.aziendaLocationAreas && setSelectionsAziendaLocation(GetUnscaleValue(JSON.parse(selection.aziendaLocationAreas)));
      selection.numeroFatturaAreas && setSelectionsNumeroFattura(GetUnscaleValue(JSON.parse(selection.numeroFatturaAreas)));
    }
  }, [invoiceAreas])


  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button !== 0) return;
    const canvas = canvasRef.current;
    if (!canvas) return;
    const canvasRect = canvas.getBoundingClientRect();

    setStartX(e.clientX - canvasRect.left);
    setStartY(e.clientY - canvasRect.top);

    setStart(true);

  };
  const handleMouseMove = (e: React.MouseEvent) => {
    if (!start) return;
    if (startY === 0 && startX === 0) return;
    if (e.button !== 0) return;


    const canvas = canvasRef.current;
    if (!canvas) return;
    const canvasRect = canvas.getBoundingClientRect();

    setEndX(e.clientX - canvasRect.left);
    setEndY(e.clientY - canvasRect.top);

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.rect(startX, startY, endX - startX, endY - startY);
    let strokeColor;

    switch (selector) {
      case "data":
        strokeColor = "red";
        break;
      case "righe":
        strokeColor = "blue";
        break;
      case "Quantità":
        strokeColor = "green";
        break;
      case "Prezzo":
        strokeColor = "orange";
        break;
      case "Prodotto":
        strokeColor = "purple";
        break;
      case "AziendaPiva":
        strokeColor = "purple";
        break;
      case "AziendaName":
        strokeColor = "brown";
        break;
      case "AziendaMail":
        strokeColor = "pink";
        break;
      case "AziendaLocation":
        strokeColor = "gray";
        break;
      case "NumeroFattura":
        strokeColor = "black";
        break;
      default:
        strokeColor = "black";
    }

    ctx.strokeStyle = strokeColor;
    ctx.stroke();

  };
  const handleMouseUp = (event: React.MouseEvent) => {
    if (event.button !== 0) return;

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    let strokeColor;

    switch (selector) {
      case "data":
        strokeColor = "red";
        break;
      case "righe":
        strokeColor = "blue";
        break;
      case "Quantità":
        strokeColor = "green";
        break;
      case "Prezzo":
        strokeColor = "orange";
        break;
      case "Prodotto":
        strokeColor = "purple";
        break;
      case "AziendaPiva":
        strokeColor = "purple";
        break;
      case "AziendaName":
        strokeColor = "brown";
        break;
      case "AziendaMail":
        strokeColor = "pink";
        break;
      case "AziendaLocation":
        strokeColor = "gray";
        break;
      case "NumeroFattura":
        strokeColor = "gray";
        break;
      default:
        strokeColor = "black";
    }

    ctx.strokeStyle = strokeColor;
    ctx.strokeRect(
      Math.min(startX, endX),
      Math.min(startY, endY),
      Math.abs(endX - startX),
      Math.abs(endY - startY)
    );

    const selection = {
      x: Math.min(startX, endX),
      y: Math.min(startY, endY),
      width: Math.abs(endX - startX),
      height: Math.abs(endY - startY),
    };

    switch (selector) {
      case "data":
        setSelectionsData(selection);
        break;
      case "righe":
        setSelectionsRighe(selection);
        break;
      case "Quantità":
        setSelectionsRigheQuantità(selection);
        break;
      case "Prezzo":
        setSelectionsRighePrezzo(selection);
        break;
      case "Prodotto":
        setSelectionsRigheProdotto(selection);
        break;
      case "AziendaName":
        setSelectionsAziendaName(selection);
        break;
      case "AziendaPiva":
        setSelectionsAziendaPiva(selection);
        break;
      case "AziendaMail":
        setSelectionsAziendaMail(selection);
        break;
      case "AziendaLocation":
        setSelectionsAziendaLocation(selection);
        break;
      case "NumeroFattura":
        setSelectionsNumeroFattura(selection);
        break;
      default:
        break;
    }
    setStart(false);
    forceUpdate()
  };
  const scaleHorizontal = (x: number) => {
    let ratio = 0;
    if (containerRef.current && imageData) {
      ratio = imageData.width / containerRef.current.clientWidth; // calcola il rapporto di dimensioni tra le due immagini
    }
    return x * ratio;
  };
  const scaleVertical = (x: number) => {
    let ratio = 0;
    if (containerRef.current && imageData) {
      ratio = imageData.height / height; // calcola il rapporto di dimensioni tra le due immagini
    }
    return x * ratio;
  };

  const sendSelections = async () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const formData = new FormData();
    if (file) {

      setLoader("ADD", "UploadImage")
      formData.append("Invoice", file, file?.name);
      let DataTrasformation = MapSection(scaleHorizontal, selectionsData, scaleVertical);

      let RigaAreaTrasformation = MapSection(scaleHorizontal, selectionsRighe, scaleVertical);

      let AziendaNameAreaTrasformation = MapSection(scaleHorizontal, selectionsAziendaName, scaleVertical);

      let AziendaPivaAreaTrasformation = MapSection(scaleHorizontal, selectionsAziendaPiva, scaleVertical);

      let AziendaMailAreaTrasformation = MapSection(scaleHorizontal, selectionsAziendaMail, scaleVertical);

      let AziendaLocationAreaTrasformation = MapSection(scaleHorizontal, selectionsAziendaLocation, scaleVertical);
      let numeroFatturaAreaTrasformation = MapSection(scaleHorizontal, selectionsNumeroFattura, scaleVertical);

      let RigaQuantitàAreaTrasformation = MapSection(scaleHorizontal, selectionsRigheQuantità, scaleVertical);


      let RigaPrezzoAreaTrasformation = MapSection(scaleHorizontal, selectionsRighePrezzo, scaleVertical);

      let RigaProdottoAreaTrasformation = MapSection(scaleHorizontal, selectionsRigheProdotto, scaleVertical);


      formData.append("DataAreas", JSON.stringify(DataTrasformation));
      formData.append("ProdottiAreas", JSON.stringify(RigaAreaTrasformation));
      formData.append("AziendaNameAreas", JSON.stringify(AziendaNameAreaTrasformation));
      formData.append("AziendaPivaAreas", JSON.stringify(AziendaPivaAreaTrasformation));
      formData.append("AziendaMailAreas", JSON.stringify(AziendaMailAreaTrasformation));
      formData.append("NumeroFatturaAreas", JSON.stringify(numeroFatturaAreaTrasformation));
      formData.append("AziendaLocationAreas", JSON.stringify(AziendaLocationAreaTrasformation));
      formData.append("RigaQuantitàAreas", JSON.stringify(RigaQuantitàAreaTrasformation));
      formData.append("RigaPrezzoAreas", JSON.stringify(RigaPrezzoAreaTrasformation));
      formData.append("RigaProdottoAreas", JSON.stringify(RigaProdottoAreaTrasformation));
      formData.append("Operation", props.operation.toString());

      let response = await fetch(basePath + "Movimentazione/UploadImage", {
        method: "POST",
        body: formData,
        headers: await GetHeader(true)

      });

      await returnByOcr(response, file);
      setLoader("REMOVE", "UploadImage")

    }


  };
  async function returnByOcr(response: Response, localFile: File) {
    let result: OcrInvoiceResult = await response.json();

    if (props.operation === 1) {
      if (result.aziendaSeller && result.aziendaSeller !== null) {
        setAzienda(result.aziendaSeller);
        if (invoiceAreas?.aziendaId !== result.aziendaSeller.id)
          rootStore.dispatch(setNotification({ message: "azienda rilevata salvare la selezione per renderla automatica", notifyType: NotifyType.warning }));
      }
    } else {
      if (result.aziendaBuyer && result.aziendaBuyer !== null) {
        setAzienda(result.aziendaBuyer);
        if (invoiceAreas?.aziendaId !== result.aziendaBuyer.id)
          rootStore.dispatch(setNotification({ message: "azienda rilevata salvare la selezione per renderla automatica", notifyType: NotifyType.warning }));
      }
    }

    props.AddFromCanvas(result.movimentazioni.map(x => {
      return {
        name: x.productName,
        size: x.size,
        sellValue: x.productVariant?.sellValue,
        buyValue: x.productVariant?.buyValue,
        quantita: x.quantity,
        prodottoId: x.productId,
        variantId: x.productVariantId,
        invoicePrice: x.invoicePrice,
        invoiceQuantity: x.invoiceQuantity,
        productName: x.productName,
        sizeId: x.sizeId,
        azienda: x.productVariant?.azienda,
        aziendaId: x.productVariant?.aziendaId,
        code: x.productVariant?.code,
        id: x.id,
        image: null,
        type: null,
        creaInDatabase: false,
        prodottiXCollo: x.productVariant?.prodottiXCollo ?? 0
      } as GetProdottoViewModel;
    }), result.azienda, result.aziendaSeller, result.aziendaBuyer, result.date as Date, localFile as File,result.numeroFattura);
  }
  const resetSelection = (selctorRewrite?: string) => {

    switch (selctorRewrite || selector) {
      case "data":
        setSelectionsData({} as { x: number; y: number; width: number; height: number });
        break;
      case "righe":
        setSelectionsRighe({} as { x: number; y: number; width: number; height: number });
        break;
      case "Quantità":
        setSelectionsRigheQuantità({} as { x: number; y: number; width: number; height: number });
        break;
      case "Prezzo":
        setSelectionsRighePrezzo({} as { x: number; y: number; width: number; height: number });
        break;
      case "Prodotto":
        setSelectionsRigheProdotto({} as { x: number; y: number; width: number; height: number });
        break;
      case "AziendaName":
        setSelectionsAziendaName({} as { x: number; y: number; width: number; height: number });
        break;
      case "AziendaPiva":
        setSelectionsAziendaPiva({} as { x: number; y: number; width: number; height: number });
        break;
      case "AziendaMail":
        setSelectionsAziendaMail({} as { x: number; y: number; width: number; height: number });
        break;
      case "AziendaLocation":
        setSelectionsAziendaLocation({} as { x: number; y: number; width: number; height: number });
        break;
      case "NumeroFattura":
        setSelectionsNumeroFattura({} as { x: number; y: number; width: number; height: number });
        break;
      default:
        setSelectionsData({} as { x: number; y: number; width: number; height: number });
        setSelectionsRighe({} as { x: number; y: number; width: number; height: number });
        setSelectionsRigheQuantità({} as { x: number; y: number; width: number; height: number });
        setSelectionsRighePrezzo({} as { x: number; y: number; width: number; height: number });
        setSelectionsRigheProdotto({} as { x: number; y: number; width: number; height: number });
        setSelectionsAziendaName({} as { x: number; y: number; width: number; height: number });
        setSelectionsAziendaMail({} as { x: number; y: number; width: number; height: number });
        setSelectionsAziendaLocation({} as { x: number; y: number; width: number; height: number });
        setSelectionsAziendaPiva({} as { x: number; y: number; width: number; height: number });
        setSelectionsNumeroFattura({} as { x: number; y: number; width: number; height: number });
        break;
    }
  };


  function GetInvoiceAreasValue(): any | undefined {
    if (listInvoice) {
      let data = (listInvoice as Array<any>)?.filter(
        (x) => x.id === invoiceAreas?.id
      );
      if (data != null && data?.length > 0) {
        return { value: data[0].id ?? "", label: data[0].name ?? "" };
      }
    }
    return undefined;
  }
  function GetSelectorValue(): any | undefined {
    let data = options.filter(
      (x) => x.value === selector
    );

    if (data != null && data?.length > 0) {
      return data[0];
    }

    return undefined;
  }

  const UnscaleHorizontal = (x: number) => {
    let ratio = 0;
    if (containerRef.current && imageData) {
      ratio = imageData.width / containerRef.current.clientWidth; // calcola il rapporto di dimensioni tra le due immagini
    }
    return x / ratio;
  };
  const UnscaleVertical = (x: number) => {
    let ratio = 0;
    if (containerRef.current && imageData) {
      ratio = imageData.height / height; // calcola il rapporto di dimensioni tra le due immagini
    }
    return x / ratio;
  };

  const GetUnscaleValue = (x: { x: number; y: number; width: number; height: number }): { x: number; y: number; width: number; height: number } => {

    x.x = UnscaleHorizontal(x.x);
    x.width = UnscaleHorizontal(x.width);
    x.y = UnscaleVertical(x.y);
    x.height = UnscaleVertical(x.height);

    return x;
  }

  const GetUnscaleValueList = (list: { x: number; y: number; width: number; height: number }[]): { x: number; y: number; width: number; height: number }[] => {

    list.map(x => {
      x.x = UnscaleHorizontal(x.x);
      x.width = UnscaleHorizontal(x.width);
      x.y = UnscaleVertical(x.y);
      x.height = UnscaleVertical(x.height);

      return x;
    });
    return list;
  }


  async function SetInvoiceAreasSelect(e: any) {
    let data = listInvoice?.filter(
      (x) => x.id === e.value
    );
    if (data != null && data?.length > 0) {
      var copy = { ...invoiceAreas };
      copy = data[0]
      setInvoiceAreas(copy)
    }
    const invoiceAreass = await GetInvoicesArea();
    setListInvoice(invoiceAreass);
  }


  async function DeleteInvoicesAreas() {
    let copy: ProcessImageRequestSave = { ...invoiceAreas };
    await DeleteInvoicesArea(copy.name ?? "");
    const invoiceAreasw = await GetInvoicesArea();
    setListInvoice(invoiceAreasw);

  }
  async function SaveInvoiceAreas() {
    let DataTrasformation = MapSection(scaleHorizontal, selectionsData, scaleVertical);
    // let RigaAreaTrasformation = MapSection(scaleHorizontal, selectionsRighe, scaleVertical);
    let AziendaNameAreaTrasformation = MapSection(scaleHorizontal, selectionsAziendaName, scaleVertical);
    let AziendaPivaAreaTrasformation = MapSection(scaleHorizontal, selectionsAziendaPiva, scaleVertical);
    let AziendaMailAreaTrasformation = MapSection(scaleHorizontal, selectionsAziendaMail, scaleVertical);
    let AziendaLocationAreaTrasformation = MapSection(scaleHorizontal, selectionsAziendaLocation, scaleVertical);
    let numeroFatturaAreaTrasformation = MapSection(scaleHorizontal, selectionsNumeroFattura, scaleVertical);
    let RigaQuantitàAreaTrasformation = MapSection(scaleHorizontal, selectionsRigheQuantità, scaleVertical);
    let RigaPrezzoAreaTrasformation = MapSection(scaleHorizontal, selectionsRighePrezzo, scaleVertical);
    let RigaProdottoAreaTrasformation = MapSection(scaleHorizontal, selectionsRigheProdotto, scaleVertical);
    let copy: ProcessImageRequestSave = { ...invoiceAreas };

    let data: ProcessImageRequestSave = {
      dataAreas: JSON.stringify(DataTrasformation),
      aziendaNameAreas: JSON.stringify(AziendaNameAreaTrasformation),
      aziendaPivaAreas: JSON.stringify(AziendaPivaAreaTrasformation),
      aziendaMailAreas: JSON.stringify(AziendaMailAreaTrasformation),
      numeroFatturaAreas: JSON.stringify(numeroFatturaAreaTrasformation),
      aziendaLocationAreas: JSON.stringify(AziendaLocationAreaTrasformation),
      rigaQuantitàAreas: JSON.stringify(RigaQuantitàAreaTrasformation),
      rigaPrezzoAreas: JSON.stringify(RigaPrezzoAreaTrasformation),
      rigaProdottoAreas: JSON.stringify(RigaProdottoAreaTrasformation),
      aziendaId: azienda.id,
      name: copy.name,
      id: null
    }
    console.log(data);
    await AddInvoicesArea(data);
    const invoiceAreasfetch = await GetInvoicesArea();
    setListInvoice(invoiceAreasfetch);
  }
  const onChangeInvoiceAreaName = (name, e) => {
    let copy: ProcessImageRequestSave = { ...invoiceAreas };
    copy.name = e.target.value
    setInvoiceAreas(copy);
  }

  const options = [
    { value: '', label: 'Seleziona un\'opzione' },
    { value: 'data', label: 'Seleziona data' },
    { value: 'Quantità', label: 'Seleziona quantità' },
    { value: 'Prezzo', label: 'Seleziona prezzo' },
    { value: 'Prodotto', label: 'Seleziona prodotto' },
    { value: 'AziendaName', label: 'Seleziona nome azienda' },
    { value: 'AziendaMail', label: 'Seleziona mail azienda' },
    { value: 'AziendaPiva', label: 'Seleziona IVA azienda' },
    { value: 'AziendaLocation', label: 'Seleziona location azienda' },
    { value: 'NumeroFattura', label: 'Seleziona Numero fattura' }
  ];
  const handleSelectionChange = (event) => {
    setSelector(event.value);
  }
  useImperativeHandle(ref, () => ({
    externalMethod: sendSelections
  }));

  return (
    <div className="container text-center position-relative">
      <div className="position-relative">
        <input
          type="file"
          name="loadImage"
          onInput={handleImageChange}
        />
        {listInvoice && <div>
          <h3 className="text-center">Scegli il modello</h3>
          <div className="d-flex justify-content-center">

            <div className="row">
              <div className="col-12">
                <SelectComponent
                  value={GetInvoiceAreasValue()}
                  options={listInvoice?.map((x) => {
                    return { value: x.id ?? "", label: x.name ?? "" };
                  })}
                  onChange={(e) => SetInvoiceAreasSelect(e)}
                  name={"modello"}
                  title={"modello"}
                  isMulti={false}
                />
              </div>
            </div>
          </div>
        </div>}
        <hr />
        <div className="row">
          <div className="col-6">
            <InputTextComponent onChange={onChangeInvoiceAreaName} value={invoiceAreas?.name} name={'nameInvoiceArea'} title={'Nome'} type={'text'} validationMessage={'Name obbligatorio'} />
          </div>
          <div className="col-3">
            <button
              type='button'
              onClick={SaveInvoiceAreas}
              className="btn btn-success mx-2"
            >
              Salva selezione        </button>
          </div>
          <div className="col-3">
            <button
              type='button'
              onClick={DeleteInvoicesAreas}
              className="btn btn-danger mx-2"
            >
              Cancella selezione       </button>
          </div>
        </div>
        <br />
        <label>seleziona l'area di <b>{selector}</b></label>
        <br />
        <SelectComponent
          options={options}
          value={GetSelectorValue()}
          onChange={handleSelectionChange}
          isMulti={false}
          name="selector"
          title="Scegli selettore"

        />

        <button type='button'
          onClick={() => resetSelection()}
          className="btn btn-warning mx-2"
        >
          Reset current selector
        </button>
        <button
          type='button'
          onClick={() => resetSelection("reset")}
          className="btn btn-danger mx-2"
        >
          Reset all selector
        </button>
        <button
          type='button'
          onClick={sendSelections}
          className="btn btn-success mx-2"
        >
          Send Selections
        </button>
      </div>

      <div ref={containerRef} className="m-0 p-0 position-relative">
        {imageData && (
          <>
            <canvas
              width={containerRef.current?.clientWidth}
              height={height}
              ref={imageCanvasRef}
            />
            <canvas
              width={containerRef.current?.clientWidth}
              height={height}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              ref={canvasRef}
              style={{ position: 'absolute', left: 0, top: 0 }}
            />
          </>
        )}
      </div>
    </div>
  );
});

export default CanvasComponent;



function MapSection(scaleHorizontal: (x: number) => number, rect: { x: number; y: number; width: number; height: number; }, scaleVertical: (x: number) => number): { x: number; y: number; width: number; height: number; } {
  return {
    x: scaleHorizontal(rect.x),
    y: scaleVertical(rect.y),
    width: scaleHorizontal(rect.width),
    height: scaleVertical(rect.height),
  } as { x: number; y: number; width: number; height: number; };
}

function DrawSection(ctx: CanvasRenderingContext2D, x: { x: number; y: number; width: number; height: number; }, color: string, label: string) {
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.rect(x.x, x.y, x.width, x.height);
  ctx.stroke();
  ctx.fillStyle = color;
  ctx.font = "12px Arial";
  ctx.fillText(label, x.x + 5, x.y - 10);
}

export interface OcrInvoiceResult {
  aziendaSeller: any
  aziendaBuyer: any
  azienda: any
  movimentazioni: Movimentazioni[]
  date: Date;
  numeroFattura:string | null
}

export interface Movimentazioni {
  id: any
  movimentazioneId: any
  productVariantId: any
  productId: any
  productName: string
  quantity: number
  productVariant: any
  productVariantName: string
  price: number
  invoiceQuantity: number
  invoicePrice: number
  size?: string | null
  sizeId?: string | null
}

