import React from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import Icon from '../../../Components/Various/Icon'
import { ProductInTripViewModel } from '../../../Model/TripModels'

interface Props {
  action?: {
    addInTrip?(data: ProductInTripViewModel): any | void,
    removeInTrip?(data: ProductInTripViewModel,index:number): any | void,
    chartBar?(data: ProductInTripViewModel): any | void,
  }
  model: ProductInTripViewModel,
  index:number
}
const ProductInTripComponent: React.FC<Props> = (props) => {



  return (
    <div className='col-12 mb-3'>
      <h4>   <Icon icon={require("../../../img/bottle-wine.png")} height={30} />
        {props.model.productName}</h4>
      <div className='card shadow-sm'>
        {/* Prima riga per le azioni */}
        <div className='row card-body'>

          {props.action && props.action.chartBar && (
            <div className='col'>
              <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action?.chartBar && props.action.chartBar(props.model)} />
            </div>
          )}
          {props.action && props.action.addInTrip && (
            <div className='col'>
              <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action?.addInTrip && props.action.addInTrip(props.model)} />
            </div>
          )}
          {props.action && props.action.removeInTrip && (
            <div className='col'>
              <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action?.removeInTrip && props.action.removeInTrip(props.model,props.index)} />
            </div>
          )}
          <div className='col'>
            {props.children}
          </div>
        </div>
        {/* Seconda riga per le etichette e i valori */}
        <div className='row card-body'>
 
          {props.model.quantity && (
            <div className='col'>
              <label><Icon icon={require("../../../img/inventory_black.png")} /> {props.model.quantity}</label>
            </div>
          )} 
            <div className='col'>
              <label><MaterialIcon icon="inventory_2" /> {props.model.colliXProdotto ?? 1} : {Math.round(props.model.quantity / (props.model.colliXProdotto ?? 1))} </label>
            </div>
        
          {props.model.azienda && (
            <div className='col'>
              <label><Icon icon={require("../../../img/store.png")} /> {props.model.azienda}</label>
            </div>
          )}
    
        </div>
      </div>
    </div> 
  )
}

export default ProductInTripComponent