import React, { useState, useEffect } from 'react';
import { formatTime } from '../../Hook/Common/TimeAndDate';
import { DateFilter } from '../../Model/CommonModel';

interface Props {
    property: string;
    orderAscending?: boolean;
    propertyInOrder?: string;
    filter: DateFilter | undefined;
    actionFilters: {
        checkFilter: (value: string) => boolean;
        resetFilter: (value: string) => void;
        setDateToChildren(data: Date | undefined, property: string, target: string): any;
    };
}

const FilterMenuDateRange: React.FC<Props> = (props) => {
    const [from, setFrom] = useState<string | undefined>();
    const [to, setTo] = useState<string | undefined>();

    // Funzione per convertire in formato compatibile con gli input "date" (yyyy-MM-dd)
    const toInputDate = (date: string | undefined): string => {
        if (!date) return '';
        const [day, month, year] = date.split('-'); // Supponiamo che formatTime restituisca "dd-MM-yyyy"
        return `${year}-${month}-${day}`;
    };

    // Sincronizza lo stato locale con `props.filter` all'inizializzazione o quando cambia
    useEffect(() => {
        setFrom(props.filter?.startDate ? toInputDate(formatTime(props.filter.startDate)) : undefined);
        setTo(props.filter?.endDate ? toInputDate(formatTime(props.filter.endDate)) : undefined);
    }, [props.filter]);

    // Gestisce il reset dei filtri
    const reset = () => {
        props.actionFilters.resetFilter(props.property);
        setFrom(undefined);
        setTo(undefined);
    };

    // Gestisce i cambiamenti degli input "date"
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>, target: string) => {
        const value = e.target.value; // Formato ISO "yyyy-MM-dd"
        const date = value ? new Date(value) : undefined;

        if (target === 'from') {
            setFrom(value);
        } else if (target === 'to') {
            setTo(value);
        }

        // Comunica il cambiamento al parent
        props.actionFilters.setDateToChildren(date, props.property, target);
    };

    return (
        <div className="relative">
            <div className="filterSearch d-flex flex-row w-100 align-items-center">
                <span className="bold mr-2">Date Range:</span>
                {(from || to) && (
                    <button
                        type="button"
                        className="btn btnTransaparent px-0"
                        onClick={reset}
                    >
                        <img src={require('../../svg/close.png')} height="13" alt="Reset" />
                    </button>
                )}
            </div>
            <div className="col-12 row mx-0 px-2 mt-2">
                <div className="col-12 pr-2">
                    <label className="w-100 bold mb-1">Da</label>
                    <input
                        type="date"
                        className="w-100 form-control"
                        value={from || ''}
                        max={to || ''}
                        onChange={(e) => changeHandler(e, 'from')}
                    />
                </div>
                <div className="col-12 pl-2">
                    <label className="w-100 bold mb-1">A</label>
                    <input
                        type="date"
                        className="w-100 form-control"
                        value={to || ''}
                        min={from || ''}
                        onChange={(e) => changeHandler(e, 'to')}
                    />
                </div>
            </div>
        </div>
    );
};

export default FilterMenuDateRange;
