import { CustomGridRender, DateFilter, FilterValueDto, QueryObject, RenderDetail, ResultDto } from "./CommonModel";


export interface Position {
  lat: number;
  lng: number;
  speed: number;
}

export interface PositionAndDates extends Position {
  date: string;
}

/**
 * 
 * @export
 * @interface VehicleViewModel
 */

export interface VehicleViewModel {
  id: string;
  licensePlate: string;
  model: string | null;
  status: string | null;
  lastMaintenanceDate: Date | null;
  nextMaintenanceDate: Date | null;
  currentLocation: string | null;
  vehicleDocumentViewModels: VehicleDocumentViewModel[];
  gpsInfo: VehicleGps | null;
}
export interface VehicleGps {
  siteGps: string | null;
  userName: string | null;
  password: string | null;
  gpsPhoneNumber: string | null;
  gpsIdentifier: string | null;
}
export interface VehicleDocumentViewModel {
  id: string;
  vehicleId: string;
  documentType: string;
  documentName: string;
  expiryDate: string;
  filePath: string;
  notes: string;
  vehicle: string;
  vehicleData: VehicleViewModel;
}

/**
 * 
 * @export
 * @interface VehicleViewModelCustomGridRender
 */
export interface VehicleViewModelCustomGridRender {
  /**
   * 
   * @type {Array<RenderDetail>}
   * @memberof VehicleViewModelCustomGridRender
   */
  render?: Array<RenderDetail>;
}


export interface VehicleViewModelQuery extends QueryObject {
  id: string | null;
  licensePlate: string[] | null;
  model: string[] | null;
  status: string[] | null;
  currentLocation: string[] | null;
  lastMaintenanceDate: DateFilter | null;
  nextMaintenanceDate: DateFilter | null;
}
export interface VehicleDocumentViewModelQuery extends QueryObject {
  id: string | null;
  vehicle: string[] | null;
  documentType: string[] | null;
  documentName: string[] | null;
  filePath: string[] | null;
  notes: string[] | null;
  expiryDate: DateFilter | null;
}

/**
 * 
 * @export
 * @interface VehicleViewModelQueryResultResponse
 */
export interface VehicleViewModelQueryResultResponse {
  /**
   * 
   * @type {number}
   * @memberof VehicleViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<VehicleViewModel>}
   * @memberof VehicleViewModelQueryResultResponse
   */
  items?: Array<VehicleViewModel>;
  /**
   * 
   * @type {VehicleViewModelCustomGridRender}
   * @memberof VehicleViewModelQueryResultResponse
   */
  gridRender?: VehicleViewModelCustomGridRender;
}
/**
 * 
 * @export
 * @interface VehicleViewModelQueryResultResponseResponseResult
 */
export interface VehicleViewModelQueryResultResponseResponseResult {
  /**
   * 
   * @type {boolean}
   * @memberof VehicleViewModelQueryResultResponseResponseResult
   */
  warning?: boolean;
  /**
   * 
   * @type {string}
   * @memberof VehicleViewModelQueryResultResponseResponseResult
   */
  info?: string;
  /**
   * 
   * @type {VehicleViewModelQueryResultResponse}
   * @memberof VehicleViewModelQueryResultResponseResponseResult
   */
  data?: VehicleViewModelQueryResultResponse;
}
/**
 * 
 * @export
 * @interface VehicleViewModelResource
 */
export interface VehicleViewModelResource extends VehicleViewModel {

}
/**
 * 
 * @export
 * @interface VehicleViewModelResourceResponseResult
 */
export interface VehicleViewModelResourceResponseResult {
  /**
   * 
   * @type {boolean}
   * @memberof VehicleViewModelResourceResponseResult
   */
  warning?: boolean;
  /**
   * 
   * @type {string}
   * @memberof VehicleViewModelResourceResponseResult
   */
  info?: string;
  /**
   * 
   * @type {VehicleViewModelResource}
   * @memberof VehicleViewModelResourceResponseResult
   */
  data?: VehicleViewModelResource;
}


export interface VehicleViewModelGrid {
  gridResult: VehicleListQueryResultDto | null
  filter: FilterValueDto[] | null
}


export interface VehicleModelDetail {
  dtoEdit: VehicleViewModelResource | null,
  resultDto: ResultDto<null> | null,
}

export interface VehicleCreate {
  dto: VehicleViewModelResource | null,
  resultDto: ResultDto<null> | null,
}
export interface VehicleEdit extends VehicleCreate {

}
export interface VehicleModelDownloaded {
  dtoDownload: number[],
}

export interface VehicleListQueryResultDto {
  /**
   *
   * @type {number}
   * @memberof VehicleListQueryResultDto
   */
  totalItems?: number;
  /**
   *
   * @type {Array<VehicleList>}
   * @memberof VehicleListQueryResultDto
   */
  items?: Array<VehicleViewModel>;
  /**
   *
   * @type {CustomGridRender}
   * @memberof VehicleListQueryResultDto
   */
  gridRender?: CustomGridRender;
}
export interface VehicleQueryResultDto {
  /**
   * 
   * @type {number}
   * @memberof VehicleViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<VehicleViewModel>}
   * @memberof VehicleViewModelQueryResultResponse
   */
  items?: Array<VehicleViewModel>;
  /**
   * 
   * @type {VehicleViewModelCustomGridRender}
   * @memberof VehicleViewModelQueryResultResponse
   */
  gridRender?: RenderDetail[];
}


export const GET_Vehicle_GRID = "GET_Vehicle_GRID"
export const ADD_Vehicle_GRID = "ADD_Vehicle_GRID"
export const GET_Vehicle_FILTERS = "GET_Vehicle_FILTERS"
export const GET_Vehicle_DETAIL = "GET_Vehicle_DETAIL"
export const GET_EDIT_Vehicle = "GET_EDIT_Vehicle"
export const EDIT_Vehicle = "EDIT_Vehicle"
export const CREATE_Vehicle = "CREATE_Vehicle"
export const GET_CREATE_Vehicle = "GET_CREATE_Vehicle"
export const DELETE_Vehicle = "DELETE_Vehicle"


//#region offline definition

export const Vehicle_LOCAL = "Vehicle_LOCAL"
export const Vehicle_FILTERS_LOCAL = "Vehicle_FILTERS_LOCAL"

export const Vehicle_GRIDRENDER = "Vehicle_GRIDRENDER"

//#endregion
