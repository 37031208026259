import React, { useContext, useState } from 'react';
import '../../Css/Index.css'
import '../../Css/AziendaList.css'
import FiltersMenuIconButton from '../../Components/FormAndFilters/FiltersMenuIconButton';
import { GetProformaCreateResource } from '../../Redux/Action/Proforma/ProformaCreateAction';
import { ProformaViewModelQuery, ProformaViewModel } from '../../Model/ProformaModels';
import Pagination from '../../Components/PaginationComponentHard';
import { RootState } from '../../Redux/Store/rootStore';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ModalConfirm from '../../Components/Various/ModalConfirm';
import { stateConfirm, DataModalConfirm } from '../../Model/CommonModel';
import { DarkTheme } from '../../App';
import SetupColumns from '../../Components/SetupColums/SetupColumns';
import setLoader from '../../Redux/Action/LoaderAction';
import { Modal } from 'react-bootstrap';
import { useOperationTableCrud } from '../../Hook/Grid/useOperationTableCrud';
import { GetAddResultToGrid, GetProformaViewModel, GetFilterColumnProformaViewModel } from '../../Redux/Action/Proforma/ProformaGridAction';
import ProformaModal from '../Proforma/ProformaModal';
import ProformaGrid from '../Proforma/ProformaGrid';
import { useResourceTableCrud } from '../../Hook/Grid/useResourceTableCrud';
import { deleteProforma } from '../../Redux/Action/Proforma/ProformaDeleteAction';
import { GetProformaReport } from '../../Redux/Action/Proforma/ProformaDownloadAction';

interface Props {
  isOnline: boolean,
  actionGridOverride?: (x:any)=> any,
}

const ProformaContainer: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  let initialState: ProformaViewModelQuery = {

    sortBy: "",
    isSortAscending: false,
    page: 1,
    pageSize: 10,
     id: null,
    //  downloadCount:null,
    //  downloadCountLimit:null,
    //  endDate:null,
     fileName:[],
    //requiredUser:null,
    email:null,      

  }

  useEffect(() => {
    if (props.isOnline === false)
      setQuery(query, true)
  }, [props.isOnline])

  //UTILITY
  const [confirmCustom, setConfirmCustom] = useState<DataModalConfirm>(stateConfirm)

  //REDUCERS
  const GridData = (state: RootState) => state.ProformaGridReducer.gridResult;
  let GridDto = useSelector(GridData);

  const LayoutData = (state: RootState) => state.layoutReducer;
  let layout = useSelector(LayoutData);

  const downloadedData = (state: RootState) => state.ProformaDownloadReducer.dtoDownload;
  let downloadedList = useSelector(downloadedData);

  //DETAIL
  const [isDetailOpened, setIsDetailOpened] = useState<boolean>(false)
  const [isVisibleModalSetup, setIsVisibleModalSetup] = useState<boolean>(false);

  const onDelete = async () => {
    await GetProformaViewModel(query).then(() => {
      setLoader("REMOVE", "GetProformaViewModel")
    });
  }


  //FILTERS
  const { query, setQuery, next, back, goToPage } = useResourceTableCrud(initialState, GetProformaViewModel, layout.isMobile ?? true, GetAddResultToGrid, GridDto?.items);

  const { New, Edit, isVisibleModal, edit, confirm, setConfirm, closeModal, Delete, localStateHistory, setLocalState } = useOperationTableCrud<ProformaViewModel, ProformaViewModel>(
    GetProformaCreateResource,
    GetProformaCreateResource,
    deleteProforma,
    onDelete
  );

  const closeModalSetup = async (changed: boolean) => {
    setIsVisibleModalSetup(false)
    await GetProformaViewModel(query).then(() => {
      setLoader("REMOVE", "GetProformaViewModel")
    });
  };

  const closeModalForm = async (changed: boolean) => {
    closeModal(changed);
    await GetProformaViewModel(query).then(() => {
      setLoader("REMOVE", "GetProformaViewModel")
    });
  };


  const InvocheDownload = async () => {
		let result = await GetProformaReport(query);
		if (result !== undefined) {
			let url = window.URL.createObjectURL(result.file);
			let a = document.createElement("a");
			a.href = url;
			a.download = result.fileName;
			a.click();
		}
	};

  return (
    <div className={` mt-3  m-3 mx-0 relative h-100 pageContainer justify-content-center ${darkTheme ? "dark" : ""}`}>
      <ModalConfirm data={confirm} />

      <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} fullscreen={true}>
        <Modal.Header className="d-flex justify-content-center">
          <div className="  px-0 mb-2">
            <div className="  mt-3">
              <h4>{edit ? "Modifica Proforma" : "Aggiungi Proforma"}</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ProformaModal action={{ closeModal: closeModalForm }} edit={edit} isVisibleModal={isVisibleModal} />
        </Modal.Body>
      </Modal>

      <div className="d-flex align-items-center justify-content-between ">
        <h2 className="mb-0">Lista Proforma</h2>
        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" type="button" onClick={() => InvocheDownload()}>
						<img alt="Download" width="26" style={{ marginBottom: "2px" }} src={require("../../img/excel.png")} />
					</button>
        <button className='btn btnOutline' type='button' onClick={() => New()}>Aggiungi Proforma</button>
      </div>
      <div className='  pt-3'>
        <div className={` pb-5 pt-4 ${layout.isMobile ? "" : "tableContainer"}`}>
          {layout.isMobile ?
            <div className="d-flex align-items-center justify-content-between ">
              <FiltersMenuIconButton containerClass={""} initialStateQuery={initialState} query={query} propertyToNotOverride={"projectCode"} action={{ applyFilters: setQuery, getFilterColumn: GetFilterColumnProformaViewModel }} renderGrid={GridDto?.gridRender?.render} />
            </div>
            :
            <div className='  pb-2 d-flex justify-content-end relative'>
              <button type='button' className='btn px-0 btnColonne d-flex align-items-center justify-content-center' onClick={() => setIsVisibleModalSetup(!isVisibleModalSetup)}>
                <img alt="colonne" src={require('../../svg/colonne.png')}></img>
                <span className='ml-2 mt-1'>Modifica Colonne</span>
              </button>
              {isVisibleModalSetup &&
                <div className="pr-5 absolute" style={{ top: "102%", right: "0", minWidth: "500px" }}>
                  <div className="orderContainer absolute">
                    <SetupColumns renderGrid={GridDto?.gridRender} action={{ closeModalSetup, setIsVisibleModalSetup }}></SetupColumns>
                  </div>
                </div>
              }
            </div>}
          <div className='col-12 h-100 paginationScrollable' id='paginationScrollable'>
            <div id="scrollableDiv" style={{ height: layout.isMobile ? 500 : "100%", overflow: "auto" }}>
              <Pagination isMobile={layout.isMobile ?? false} pagination={{ page: query.page, pageSize: query.pageSize }} totalItems={GridDto?.totalItems ?? 0} itemsLength={GridDto?.items?.length ?? 0} actions={{ next, back, goToPage }} >
                <ProformaGrid  isMobile={layout.isMobile ?? false} action={{ setConfirm, setQuery, Edit, onDelete: Delete }} data={GridDto?.items ?? []} downloadedList={downloadedList} query={query} renderGrid={GridDto?.gridRender?.render} />
              </Pagination>
            </div></div>
        </div>
      </div>
    </div>
  );
}
export default ProformaContainer


