import React, { useState, useRef, useEffect } from 'react';
import '../../Css/Table.css';
import { Popover } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

interface Props {
    propertyName: string;
    spanClassName?: string;
    overridePropertyName?: string;
    hideFilter?: boolean;
    hideHeader?: boolean;
    thAction: {
        orderBy(property: string, isAscending: boolean): any;
    };
    isAscending: boolean | undefined;
    propertyInOrder: string | undefined;
    hasActiveFilters?: boolean;
}

const TableCrudTh: React.FC<Props> = (props) => {
    const propertyName = props.overridePropertyName ?? props.propertyName;
    const [showPopover, setShowPopover] = useState(false);
    const [popoverPosition, setPopoverPosition] = useState<'left' | 'right' | 'center'>('center');
    const popoverRef = useRef<HTMLDivElement | null>(null);

    const handleTogglePopover = () => {
        setShowPopover(!showPopover);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
            setShowPopover(false);
        }
    };

    useEffect(() => {
        if (showPopover) {
            document.addEventListener('mousedown', handleClickOutside);

            // Rileva la posizione del Popover rispetto allo schermo
            if (popoverRef.current) {
                const bounding = popoverRef.current.getBoundingClientRect();
                if (bounding.right > window.innerWidth) {
                    setPopoverPosition('left');
                } else if (bounding.left < 0) {
                    setPopoverPosition('right');
                } else {
                    setPopoverPosition('center');
                }
            }
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPopover]);

    return (
        <th className="gridHead w-100 px-0" key={props.propertyName}>
            <div className="px-2 w-100 d-flex align-items-start flex-row justify-content-between labelTh align-content-start">
                {!props.hideHeader && (
                    <div className="d-flex align-items-center headerTable text-nowrap">
                        <label className={props.spanClassName}>{propertyName}</label>
                        <div className="d-flex align-items-center ml-2">
                            {/* Mostra il pulsante di ordinamento */}
                            <button
                                type="button"
                                className={`btnIcon btnOrder ${
                                    props.propertyInOrder === props.propertyName ? 'active' : ''
                                }`}
                                onClick={() =>
                                    props.thAction.orderBy(
                                        props.propertyName,
                                        props.propertyInOrder === props.propertyName
                                            ? !props.isAscending
                                            : true // Imposta Ascendente per la prima selezione
                                    )
                                }
                            >
                                {props.propertyInOrder === props.propertyName ? (
                                    <img
                                        src={require(`../../svg/arrow_${
                                            props.isAscending ? 'up' : 'down'
                                        }_Gray.png`)}
                                        alt="Order Icon"
                                    />
                                ) : (
                                    <i className="fas fa-sort" aria-hidden="true"></i> // Icona neutra
                                )}
                            </button>

                            {/* Filtro */}
                            {!props.hideFilter && props.children && (
                                <div ref={popoverRef} className="position-relative">
                                    <button
                                        className="btn btn-link p-0 m-0 ml-2"
                                        style={{ textDecoration: 'none' }}
                                        onClick={handleTogglePopover}
                                    >
                                        <i
                                            className={`fas fa-filter ${
                                                props.hasActiveFilters ? 'active-filter' : ''
                                            }`}
                                        ></i>
                                    </button>
                                    {showPopover && (
                                        <Popover
                                            id={`popover-${props.propertyName}`}
                                            className={`custom-popover ${popoverPosition}`}
                                            style={{
                                                position: 'absolute',
                                                zIndex: 10,
                                                transform:
                                                    popoverPosition === 'left'
                                                        ? 'translateX(-100%)'
                                                        : popoverPosition === 'right'
                                                        ? 'translateX(0)'
                                                        : 'translateX(-50%)',
                                            }}
                                        >
                                            <Popover.Body>{props.children}</Popover.Body>
                                        </Popover>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </th>
    );
};

export default TableCrudTh;
